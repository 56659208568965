<template>
    <span :class="expectColor" :title="row['destinationIP']">{{row["destination"]}}</span>
</template>
<script>
    import {getExpectColor} from "@/js/helper.js";

    export default {
        props: ["row"],
        computed: {
            expectColor() {
                return getExpectColor(this.row);
            }
            ,
        }
    };
</script>